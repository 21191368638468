const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://civi.dvgeo.app' : 'http://192.168.100.106:3037',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://civi.dvgeo.app' : 'http://192.168.100.106:3037',
    api: 'api/',
    apiSocket: 'civi::1.2',
    nameDir: 'civi',
    package: 'app.dvgeo.civi.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyC6ZPyUWAcdc-RFB6V0pz09PHEOeGdTrR8',
    appName: 'CIVI',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#FF7601',
    colorDark: '#d25e01',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.civi.passenger',
    playStoreDriverId: 'app.dvgeo.civi.driver',
    appStorePassengerId: '',
    appStoreDriverId: '',
    email: "t.lollevo.ar@gmail.com",
};
export default config;
